<template>
    <div class="fl-y-cc full-height">
        <h1>Welcome to Commandar</h1>
        <small>Commandar is a Linux terminal commands generator</small>
        <validated-input class="w-40r" placeholder="Enter your requirement here" v-model="requirement" @keydown.enter="process">
            <template #prepend>
                <RecordButton class="mr-3" size="sm" color="success" @text="onRecord" />
            </template>
        </validated-input>

        <code class="mt-3" v-if="command" v-text="command"></code>
    </div>
</template>

<script>
import RecordButton from '@components/RecordButton.vue';
import groq from '@/plugins/groq';

export default {
    name: 'Commandar',
    components: { RecordButton },
    data () { return { requirement: '', command: '' }; },
    mounted () { window.document.title = 'Commandar'; },
    methods: {
        onRecord (text) {
            this.requirement = text;
            this.process();
        },

        async textPrompt (prompt) {
            this.command = await groq.chatCompletion([
                { role: 'system', content: prompt },
                { role: 'user', content: this.requirement }
            ]);
        },

        process () {
            this.textPrompt('I want you to act as a Linux terminal command generator. ' +
                'You have to take the input requirement and generate the required command. ' +
                'You should only output the command and nothing else. ' +
                'If the question is not related to any Linux commands, do not write anything.');
        }
    }
};
</script>
