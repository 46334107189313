<template>
    <div class="container">
        <h1>Create Document</h1>
        <s-form class="row" @submit="createDocument">
            <div class="col-md-12">
                <validated-input label="Name" v-model="model.name"/>
            </div>
            <div class="col-md-12 mb-3">
                <markdown-editor v-model="model.system_prompt" label="System Prompt"/>
            </div>
            <div class="col-md-12 mb-3">
                <markdown-editor v-model="model.user_prompt" label="Prompt"/>
            </div>
            <div class="col-md-12">
                <btn text="Create Document" color="success" icon="fa fa-plus" @click="createDocument"/>
            </div>
        </s-form>
    </div>
</template>

<script>
import MarkdownEditor from '@components/forms/markdown-editor.vue';
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'CreateDocument',
    components: { MarkdownEditor },
    data () {
        return {
            model: {
                name: '',
                system_prompt: '',
                user_prompt: ''
            }
        };
    },

    methods: {
        async createDocument () {
            try {
                const response = await axios.form(urls.docs.create, {
                    ...this.model
                });

                const data = response.data;
                const id = data.id;
                await this.$router.push({
                    name: 'DocumentEditor',
                    params: { id: id }
                });
            } catch (error) {
                console.error(error);
            }
        }
    }
};
</script>
