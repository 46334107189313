<template>
    <chat-box-layout class="full-height" left-box-class="br-1">
        <template #left-box>
            <div class="p-3">
                <span class="text-2x">Catalist</span>
                <b class="ml-2 text-primary">Emails</b>
            </div>
            <div class="fl-gr-1">
                <div class="m-2 bg-2 round-1 p-3 bs-5h bb-1" v-for="bot in bots" :key="bot.id" @click="selectBot(bot)">
                    <a :class="{'text-primary': selected && bot.id === selected.id}">
                        <b>{{ bot.name }}</b>
                    </a>
                    <p class="text-justify">{{ bot.description }}</p>
                </div>
            </div>
        </template>

        <template #top-box>
            <div class="container mt-4 fl-gr-1" v-if="selected">
                <div class="fl-te-c mb-2">
                    <b>Email Prompt</b>
                    <div>
                        <btn icon="fa fa-save" :loading="performing" :disabled="performing" loading-text="Saving.." design="basic-b" text="Save" @click="savePrompt" />
                    </div>
                </div>
                <markdown-editor v-model="prompt" />
            </div>

            <div class="container mt-4 fl-y" v-else-if="selected">
                <div class="bg-info card fl-x-c">
                    <loading-animation class="mr-2"/>
                    <span>Loading prompt from server</span>
                </div>
            </div>

            <div class="container mt-4 fl-y" v-else>
                <div class="bg-info card">Please select a Email Type</div>
            </div>
        </template>
    </chat-box-layout>
</template>
<script>
import ChatBoxLayout from '@components/layouts/ChatBoxLayout.vue';
import axios from 'secure-axios';
import MarkdownEditor from '@components/forms/markdown-editor.vue';
import urls from '../../data/urls';

export default {
    name: 'Emails',
    components: {
        MarkdownEditor,
        ChatBoxLayout
    },
    data () {
        return {
            bots: [],
            performing: false,
            selected: null,
            prompt: ''
        };
    },
    async mounted () {
        try {
            const response = await axios.get(urls.bots.listBots);
            this.bots = response.data.response;
            console.log(this.bots);
        } catch (error) {
            console.error(error);
        }
    },

    methods: {
        async selectBot (bot) {
            this.selected = bot;
            this.prompt = '';

            try {
                const response = await axios.get(urls.bots.promptBots + this.selected.id + '/');
                this.prompt = response.data;
            } catch (error) {
                console.error(error);
            }
        },

        async savePrompt () {
            try {
                this.performing = true;
                const headers = { 'Content-Type': 'text/plain' };
                const config = { headers: headers };
                const response = await axios.post(urls.bots.updateBots + this.selected.id + '/', this.prompt, config);
                this.selected.prompt = this.prompt;
                console.log(response.data);
            } catch (error) {
                console.error(error);
            }
            this.performing = false;
        }
    }
};
</script>
