<template>
    <div>
        <div v-if="changed && !loading" style="position: fixed; right: 1rem; top: 1rem; z-index: 1000; display: inline-block">
            <btn icon="fa fa-save" text="Save" @click="saveDocument" :loading="saving"/>
        </div>

        <div>
            <MarkdownEditor v-model="content" @input="onChange"/>
        </div>

        <div v-if="!saving" class="container" style="position:sticky; bottom: 1.5rem; ">
            <div class="bs-4 mb-2 bg-2">
                <validated-input @keydown.enter="suggestChanges" v-model="prompt" placeholder="Give some suggestions" :disabled="loading">
                    <template #append>
                        <btn icon="fa fa-send" text="" loading-text="" @click="suggestChanges" :loading="loading"/>
                    </template>
                </validated-input>
            </div>
        </div>
    </div>
</template>

<script>
import MarkdownEditor from '@components/forms/markdown-editor.vue';
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    components: { MarkdownEditor },
    data: function () {
        return {
            documentId: this.$route.params.id,
            prompt: '',
            content: '',
            loading: false,
            changed: false,
            saving: false
        };
    },

    mounted () {
        this.loadDocument();
    },

    methods: {
        async loadDocument () {
            this.loading = true;
            try {
                const response = await axios.form(urls.docs.get, {
                    id: this.documentId
                });
                this.content = response.data;
            } catch (error) {
                console.error(error);
            }
            this.loading = false;
            this.changed = false;
        },

        async saveDocument () {
            this.changed = false;
            this.saving = true;
            try {
                await axios.form(urls.docs.save, {
                    id: this.documentId,
                    content: this.content
                });
            } catch (error) {
                console.error(error);
            }
            this.saving = false;
            this.changed = false;
        },

        onChange () {
            this.changed = this.loading !== true;
        },

        async suggestChanges () {
            this.loading = true;
            try {
                const params = {
                    document: this.documentId,
                    prompt: this.prompt
                };

                const response = await axios.form(urls.docs.suggestChange, params);
                this.prompt = '';
                this.content = response.data.document;
                this.changed = false;
            } catch (error) {
                console.error(error);
            }
            this.loading = false;
        }
    }
};
</script>
