const baseURL = 'https://api.groq.com/openai/v1';

async function performAPI (url, body) {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Authorization: 'Bearer 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'
        },
        body: body
    });

    const data = await response.json();
    return data;
}

async function convertAudioToText (audioBlob) {
    const formData = new FormData();
    formData.append('file', audioBlob, 'audio.wav');
    formData.append('model', 'whisper-large-v3');
    formData.append('response_format', 'json');

    try {
        const url = baseURL + '/audio/translations';
        const data = await performAPI(url, formData);
        return data.text;
    } catch (error) {
        return '';
    }
}

async function chatCompletion (messages, model = 'llama-3.3-70b-versatile') {
    const url = baseURL + '/chat/completions';
    const body = JSON.stringify({
        model: model,
        messages: messages
    });
    const data = await performAPI(url, body);
    console.log(data);
    return data.choices[0].message.content;
}

export default {
    convertAudioToText,
    chatCompletion
};
