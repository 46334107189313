<template>
    <div class="p-3 pb-0 mb-2 round-2" :class="parentClass">
        <b v-if="caption" v-text="caption"></b>
        <span class="d-block" v-html="message"/>
    </div>
</template>

<script>
export default {
    name: 'ChatBubble',
    props: {
        message: {
            type: String,
            required: true
        },
        isUser: {
            type: Boolean,
            default: false
        },
        caption: {
            type: String,
            default: ''
        }
    },
    computed: {
        parentClass () {
            return this.isUser ? 'b-1' : 'bg-3';
        }
    }
};
</script>
