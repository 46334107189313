<template>
    <btn :text="buttonText" @click="buttonClicked" :size="size" :color="color"/>
</template>

<script>
import groq from '@/plugins/groq';

export default {
    props: {
        size: { type: String, default: 'md' },
        color: { type: String, default: 'primary' }
    },
    data: function () {
        return {
            isRecording: false,
            isConverting: false,
            mediaRecorder: null,
            audioChunks: []
        };
    },

    computed: {
        buttonText: function () {
            if (this.isConverting) {
                return 'Converting...';
            }
            return this.isRecording ? 'Stop' : 'Record';
        }
    },

    methods: {
        buttonClicked: async function () {
            if (this.isConverting) {
                return;
            }
            if (this.isRecording) {
                this.mediaRecorder.stop();
            } else {
                await this.setupMediaRecorder();
                this.mediaRecorder.start();
                this.isRecording = true;
            }
        },

        setupMediaRecorder: async function () {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                this.mediaRecorder = new MediaRecorder(stream);

                this.mediaRecorder.ondataavailable = event => {
                    this.audioChunks.push(event.data);
                };

                this.mediaRecorder.onstop = async () => {
                    this.isRecording = false;
                    this.isConverting = true;
                    const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
                    this.audioChunks = [];
                    const converted = await groq.convertAudioToText(audioBlob);
                    this.$emit('text', converted);
                    this.isConverting = false;
                };
            } catch (error) {
                console.error(error);
            }
        }
    }
};
</script>
