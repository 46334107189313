<template>
    <div class="full-height fl-y">
        <top-bar description="Interview">
            <div class="w-25r mr-3">
                <btn v-if="interviewId === 0" color="success" text="Start the Interview" @click="startInterview"/>
            </div>
        </top-bar>

        <div style="max-height: 100%; margin-bottom: 250px;" class="container">
            <div class="fl-gr-1 p-2 fl-y" style="overflow: scroll;" ref="messageContainer">
                <chat-bubble
                    :key="index"
                    v-for="( message, index) in messages"
                    :is-user="message.isUser"
                    :caption="message.caption"
                    :message="message.message"
                />

                <div v-if="loading" class="b-1 p-3 round-2">
                    <loading-animation /> Loading...
                </div>
            </div>
        </div>

        <div style="position: fixed; bottom: 0; left: 0; right: 0" class="bg-2 bt-1 pt-2">
            <div class="p-2 container">
                <validated-text-area :disabled="loading" @keyup.enter.ctrl="sendAnswer" v-model="answer" class="bg-2" textarea-classes="min-h-8r"/>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import TopBar from '@components/TopBar.vue';
import ChatBubble from '@components/ChatBubble.vue';
import urls from '../../data/urls';

export default {
    name: 'Interview',
    components: {
        ChatBubble,
        TopBar
    },
    data () {
        return {
            loading: false,
            interviewId: 0,
            answer: '',
            messages: []
        };
    },

    methods: {
        async startInterview () {
            try {
                const id = prompt('Enter the interview id');
                this.interviewId = id;
                const data = { interview_id: id };
                const response = await axios.form(urls.interview.questions, data);
                console.log(response);
                console.log(response.data);

                const questions = response.data.questions;
                // Loop questions
                for (let i = 0; i < questions.length; i++) {
                    const question = questions[i];
                    this.messages.push({
                        caption: 'Question',
                        isUser: false,
                        message: question.question
                    });

                    const answered = question.answered;
                    if (answered) {
                        this.messages.push({
                            isUser: true,
                            message: question.answer
                        });

                        this.messages.push({
                            isUser: false,
                            message: question.comment
                        });
                    }
                }
            } catch (error) {
                console.error(error);
            }
            this.doScroll();
        },

        doScroll () {
            const messageContainer = this.$refs.messageContainer;
            setTimeout(() => {
                const lastChild = messageContainer.lastElementChild;
                if (lastChild) {
                    lastChild.scrollIntoView({ behavior: 'smooth' });
                }
            }, 200);
        },

        async sendAnswer () {
            this.loading = true;
            try {
                const answer = this.answer;

                const data = {
                    interview_id: this.interviewId,
                    answer
                };
                const response = await axios.form(urls.interview.answer, data);
                this.messages.push({
                    isUser: true,
                    message: answer
                });

                const obj = response.data;

                const completed = obj.completed;

                this.messages.push({
                    isUser: false,
                    message: obj.comment
                });

                if (completed) {
                    this.messages.push({
                        isUser: false,
                        message: 'Interview has been completed'
                    });
                } else {
                    this.messages.push({
                        caption: 'Question',
                        isUser: false,
                        message: obj.question
                    });
                }
            } catch (error) {
                console.error(error);
            }

            this.answer = '';
            this.loading = false;
            this.doScroll();
        }
    }
};
</script>
