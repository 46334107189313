<template>
    <div class="fl-y-cc full-height">
        <div class="fl-y-c">
            <div class="fl-te-c w-40r bg-1 p-2 heading">
                <h4>Speech to Text</h4>
                <div class="btn-group">
                    <RecordButton size="sm" @text="onRecorded"/>
                    <btn size="sm" text="Copy" class="ml-2" icon="fa fa-copy" v-if="text" @click="copyText"/>
                </div>
            </div>

            <div class="form-group" v-if="!processed">
                <textarea v-model="text" class="form-control w-40r h-10r"></textarea>
            </div>

            <div class="bs-4 form-group" v-if="processed">
                <textarea v-model="processed" class="form-control w-40r h-10r"></textarea>
            </div>

            <div class="bg-info fl-x-c p-2 w-100p" v-if="processing">
                <loading-animation />
                <span class="ml-2">Processing the text...</span>
            </div>

            <div class="fl-y-l" v-else-if="text">
                <b class="mb-2">Apply some transformations</b>
                <div class="fl-x btn-group w-40r" v-if="text">
                    <btn size="sm" text="Rewrite" @click="basicFix"/>
                    <btn size="sm" text="Email" @click="promptEmail"/>
                    <btn size="sm" text="Professional" @click="professionalMessage"/>
                    <btn size="sm" text="Friendly" @click="friendlyMessage"/>
                    <btn size="sm" text="Process Note" @click="stepByStepProcess"/>
                    <btn size="sm" class="fl-sa-e" color="info" text="Original" @click="clearProcessing"/>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import RecordButton from '../../components/RecordButton.vue';
import clipboard from '@/plugins/clipboard';
import groq from '@/plugins/groq';

export default {
    components: { RecordButton },

    data: function () {
        return {
            text: '',
            processed: '',
            processing: false
        };
    },

    mounted () {
        window.document.title = 'Speech to Text - Catalist Utility';
    },

    methods: {
        onRecorded (text) {
            this.text = text;
            this.processed = '';
        },

        copyText () {
            if (this.processed) {
                clipboard.copy(this.processed);
            } else {
                clipboard.copy(this.text);
            }
        },

        clearProcessing () {
            this.processed = '';
        },

        async textPrompt (prompt) {
            this.processing = true;
            this.processed = await groq.chatCompletion([
                {
                    role: 'system',
                    content: prompt + '\n\nDo not add any additional comments.'
                },
                {
                    role: 'user',
                    content: this.text
                }
            ]);
            this.processing = false;
        },

        async simplePrompt (prompt) {
            this.processing = true;
            this.processed = await groq.chatCompletion([
                { role: 'user', content: prompt }
            ]);
            this.processing = false;
        },

        async promptEmail () {
            const prompt = 'I want you to act as a content writer. ' +
                'Your job is to rewrite the given user input in the meaningful email message. ' +
                'There is no need to generate any subject for the email, just give me the email message body.';
            await this.textPrompt(prompt);
        },

        async friendlyMessage () {
            const prompt = 'I want you to act as a content writer. ' +
                'Your job is to rewrite the text given by user in to a friendly tone.';
            await this.textPrompt(prompt);
        },

        async professionalMessage () {
            const prompt = 'I want you to act as a content writer. ' +
                'Your job is to rewrite the given user input in a professional tone.';
            await this.textPrompt(prompt);
        },

        async basicFix () {
            const prompt = `Please fix the following text

            ${this.text}

            ---
            Do no write any extra comments on the response.
            `;

            await this.simplePrompt(prompt);
        },

        async stepByStepProcess () {
            const prompt = 'I want you to act as a technical process writer. ' +
                '### **Prompt: Convert Input into Step-by-Step Documentation**  \n' +
                '\n' +
                '**Objective:**  \n' +
                'Transform the provided input into a clear, structured, and comprehensive step-by-step documentation that explains the process in an easy-to-follow manner.\n' +
                '\n' +
                '**Instructions:**  \n' +
                '1. **Analyze the Input**  \n' +
                '   - Identify the key elements of the input and break them down into logical sections.  \n' +
                '   - Determine the main goal and intended audience of the documentation.  \n' +
                '\n' +
                '2. **Title & Introduction**  \n' +
                '   - Create a descriptive title for the documentation.  \n' +
                '   - Write a concise introduction explaining the purpose of the document and what readers can expect.  \n' +
                '\n' +
                '3. **Prerequisites** *(if applicable)*  \n' +
                '   - List any necessary tools, knowledge, or setup required before following the steps.  \n' +
                '\n' +
                '4. **Step-by-Step Process**  \n' +
                '   - Break down the process into **clear**, **numbered** steps.  \n' +
                '   - Use concise, actionable instructions in each step.  \n' +
                '   - Include **sub-steps** where necessary for better clarity.  \n' +
                '   - Add **code snippets**, **commands**, or **screenshots** if applicable.  \n' +
                '\n' +
                '5. **Expected Outcome**  \n' +
                '   - Describe what the user should expect after completing all the steps.  \n' +
                '   - Highlight any verifications, checks, or expected results.  \n' +
                '\n' +
                '6. **Troubleshooting & FAQs** *(Optional but recommended)*  \n' +
                '   - List common issues and solutions.  \n' +
                '   - Provide additional resources or references.  \n' +
                '\n' +
                '7. **Conclusion**  \n' +
                '   - Summarize the key takeaways from the documentation.  \n' +
                '   - Provide any relevant links or references for further reading.';

            await this.textPrompt(prompt);
        }

    }
};
</script>

<style>
textarea {
    resize: none !important;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.heading {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}

.fl-sa-s {
    align-self: flex-start;
}
.fl-sa-e {
    align-self: flex-end;
}
</style>
